import Vue from 'vue'

const service = {
  login: function (data) {
    return new Promise (async function (resolve, reject) {
      let settings = { 
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      }
      const body = {
        grant_type: "password",
        client_id: Vue.prototype.$client_id,
        client_secret: Vue.prototype.$client_secret,
        username: data.username,
        password: data.password
      }
      const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/oauth/token`, body, settings)
      resolve (result)
    })
  },
  getRole: function() {
    return new Promise (async function (resolve, reject) {
      const result = await Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/me`,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  }
}

export { service }
