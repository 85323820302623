import Vue from 'vue'

const service = {
  getAllPreemptionDocument: function (params) {
    return new Promise (function (resolve, reject) {
      Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/document/preemption?page=${params.page}&keyword_document_no=${params.keyword_document_no}&keyword_status=${params.keyword_status}&keyword_branch=${params.keyword_branch}&keyword_is_insulator=${params.keyword_is_insulator}`,
        headers: { 'content-type': 'application/json' }
      })
      .then (res => { 
        resolve (res) 
      })
      .catch (err => { 
        reject (err) 
      })
    })
  },
  getPreemptionDocument: function (params) {
    return new Promise (async function (resolve, reject) {
      const result = await Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/document/preemption/${params._id}`,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
  approvePreemptionDocument: function (params) {
    return new Promise (async function (resolve, reject) {
      const result = await Vue.prototype.$axios ({
        method: 'post',
        url: `${Vue.prototype.$host}/api/v1/document/preemption/approve/${params.uuid}`,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
  rejectPreemptionDocument: function (params) {
    return new Promise (async function (resolve, reject) {
      const result = await Vue.prototype.$axios ({
        method: 'post',
        url: `${Vue.prototype.$host}/api/v1/document/preemption/decline/${params.uuid}`,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
  getAllRequestDocument: function (params) {
    return new Promise (function (resolve, reject) {
      Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/document/request?page=${params.page}&keyword_document_no=${params.keyword_document_no}&keyword_status=${params.keyword_status}&keyword_branch=${params.keyword_branch}`,
        headers: { 'content-type': 'application/json' }
      })
      .then (res => { 
        resolve (res) 
      })
      .catch (err => { 
        reject (err) 
      })
    })
  },
  getRequestDocument: function (params) {
    return new Promise (async function (resolve, reject) {
      const result = await Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/document/request/${params._id}`,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
  getAllInvoiceDocument: function (params) {
    return new Promise (function (resolve, reject) {
      Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/document/invoice?page=${params.page}&keyword_document_no=${params.keyword_document_no}
        &keyword_status=${params.keyword_status}&keyword_start=${params.keyword_start}&keyword_end=${params.keyword_end}&keyword_branch=${params.keyword_branch}`,
        headers: { 'content-type': 'application/json' }
      })
      .then (res => { 
        resolve (res) 
      })
      .catch (err => { 
        reject (err) 
      })
    })
  },
  getInvoiceDocument: function (params) {
    return new Promise (function (resolve, reject) {
      Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/document/invoice/${params._id}`,
        headers: { 'content-type': 'application/json' }
      })
      .then (res => { 
        resolve (res) 
      })
      .catch (err => { 
        reject (err) 
      })
    })
  },
}

export { service }
