import { service } from './receipts.service'

// import Vue from 'vue'
const state = { }

const getters = {
  // state is module's local state
}

const mutations = {
  // state is assigned a value
}

const actions = {
  // first param is context object
  create: function ({ commit }, data) {
    return new Promise (async (resolve, reject) => {
      const result = await service.create(data)
      resolve (result) 
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
