import { service } from './setting.service'

// import Vue from 'vue'
const state = { }

const getters = {
  // state is module's local state
}

const mutations = {
  // state is assigned a value
}

const actions = {
  get: function ({ commit }) {
    return new Promise (async (resolve, reject) => {
      const result = await service.get()
      resolve (result) 
    })
  },
  update: function ({ commit }, data) {
    return new Promise (async (resolve, reject) => {
      const result = await service.update(data)
      resolve (result) 
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
