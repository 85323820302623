import Vue from 'vue'

const service = {
  create: function (formData) {
    return new Promise (async function (resolve, reject) {
      let settings = { 
        headers: { 'content-type': 'multipart/form-data; boundary=${form._boundary' },
        validateStatus: () => true
      }
      const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/receipt`, formData, settings)
      resolve (result)
    })
  }
}

export { service }
