import { service } from './machines.service'

// import Vue from 'vue'
const state = { }

const getters = {
  // state is module's local state
}

const mutations = {
  // state is assigned a value
}

const actions = {
  // first param is context object
  getAll: function () {
    return new Promise ((resolve, reject) => {
      service.getAll().then (res => { 
        resolve (res) 
      }).catch (err => { 
        reject (err) 
      })
    })
  },
  get: function ({ commit }, params) {
    return new Promise (async (resolve, reject) => {
      const result = await service.get(params)
      resolve (result) 
    })
  },
  search: function ({ commit }, params) {
    return new Promise (async (resolve, reject) => {
      const result = await service.search(params)
      resolve (result) 
    })
  },
  delete: function ({ commit }, params) {
    return new Promise (async (resolve, reject) => {
      const result = await service.delete(params)
      resolve (result) 
    })
  },
  create: function ({ commit }, data) {
    return new Promise (async (resolve, reject) => {
      const result = await service.create(data)
      resolve (result) 
    })
  },
  update: function ({ commit }, data) {
    return new Promise (async (resolve, reject) => {
      const result = await service.update(data)
      resolve (result) 
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
