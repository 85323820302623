import { service } from './documents.service'

// import Vue from 'vue'
const state = { }

const getters = {
  // state is module's local state
}

const mutations = {
  // state is assigned a value
}

const actions = {
  // first param is context object
  getAllPreemptionDocument: function ({ commit }, params) {
    return new Promise ((resolve, reject) => {
      service.getAllPreemptionDocument(params).then (res => { 
        resolve (res) 
      }).catch (err => { 
        reject (err) 
      })
    })
  },
  getPreemptionDocument: function ({ commit }, params) {
    return new Promise (async (resolve, reject) => {
      const result = await service.getPreemptionDocument(params)
      resolve (result) 
    })
  },
  approvePreemptionDocument: function ({ commit }, params) {
    return new Promise (async (resolve, reject) => {
      const result = await service.approvePreemptionDocument(params)
      resolve (result) 
    })
  },
  rejectPreemptionDocument: function ({ commit }, params) {
    return new Promise (async (resolve, reject) => {
      const result = await service.rejectPreemptionDocument(params)
      resolve (result) 
    })
  },
  getAllRequestDocument: function ({ commit }, params) {
    return new Promise ((resolve, reject) => {
      service.getAllRequestDocument(params).then (res => { 
        resolve (res) 
      }).catch (err => { 
        reject (err) 
      })
    })
  },
  getRequestDocument: function ({ commit }, params) {
    return new Promise (async (resolve, reject) => {
      const result = await service.getRequestDocument(params)
      resolve (result) 
    })
  },
  getAllInvoiceDocument: function ({ commit }, params) {
    return new Promise ((resolve, reject) => {
      service.getAllInvoiceDocument(params).then (res => { 
        resolve (res) 
      }).catch (err => { 
        reject (err) 
      })
    })
  },
  getInvoiceDocument: function ({ commit }, params) {
    return new Promise (async (resolve, reject) => {
      const result = await service.getInvoiceDocument(params)
      resolve (result) 
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
