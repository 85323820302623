import Vue from 'vue'
const service = {
  getAll: function (params) {
    return new Promise (async function (resolve, reject) {
      const url = params?.url?? `${Vue.prototype.$host}/api/v1/deposits?keyword=${params.keyword}&keyword_status=${params.keyword_status}`
      const result = await Vue.prototype.$axios ({
        method: 'get',
        url: url,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
  get: function (params) {
    return new Promise (async function (resolve, reject) {
      const result = await Vue.prototype.$axios ({
        method: 'get',
        url: `${Vue.prototype.$host}/api/v1/deposit/${params._id}`,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
  update: function (formData, Id) {
    return new Promise (async function (resolve, reject) {
      let settings = { 
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      }
      const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/deposit/${Id}`, formData, settings)
      resolve (result)
    })
  },
  create: function (formData) {
    return new Promise (async function (resolve, reject) {
      let settings = { 
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      }
      const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/deposit`, formData, settings)
      resolve (result)
    })
  },
  delete: function (params) {
    return new Promise (async function (resolve, reject) {
      const result = await Vue.prototype.$axios ({
        method: 'delete',
        url: `${Vue.prototype.$host}/api/v1/deposit/${params.id}`,
        headers: { 'content-type': 'application/json' },
        validateStatus: () => true
      })
      resolve (result)
    })
  },
}

export { service }
