import { service } from "./auth.service";

// import Vue from 'vue'
const state = {
  user: null,
};

const getters = {
  // state is module's local state
  // this.$store.getters['Auth/user']
  user: (state) => state.user,
};

const mutations = {
  // state is assigned a value
  user: function (state, value) {
    state.user = value;
  },
};

const actions = {
  // first param is context object
  login: function ({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      const result = await service.login(data);
      if (result.status === 200 && result.data.access_token) {
        localStorage.Sangthai_isLoggedIn = true;
        localStorage.Sangthai_access_token = result.data.access_token;
        localStorage.Sangthai_expires_in = result.data.expires_in;
        localStorage.Sangthai_refresh_token = result.data.refresh_token;
      }
      resolve(result);
    });
  },
  getRole: function ({ commit }, params) {
    return new Promise(async (resolve, reject) => {
      const result = await service.getRole(params);

      if (result.status === 401) {
        localStorage.Sangthai_isLoggedIn = false;
        localStorage.Sangthai_access_token = null;
        localStorage.Sangthai_expires_in = null;
        localStorage.Sangthai_refresh_token = null;

        window.location = "/";
      } else {
        commit("user", result?.data);
        resolve(result);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
