import Vue from 'vue'
const service = {
  getAll: function () { },
  get: function (params) { },
  search: function (params) { },
  delete: function (params) { },
  update: function (formData, Id) {
    return new Promise (async function (resolve, reject) {
      let settings = { 
        headers: { 'content-type': 'multipart/form-data; boundary=${form._boundary' },
        validateStatus: () => true
      }
      const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/invoice/${Id}`, formData, settings)
      resolve (result)
    })
  },
  create: function (formData) {
    return new Promise (async function (resolve, reject) {
      let settings = { 
        headers: { 'content-type': 'multipart/form-data; boundary=${form._boundary' },
        validateStatus: () => true
      }
      const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/invoice`, formData, settings)
      resolve (result)
    })
  }
}

export { service }
