import { service } from './stock.service'

// import Vue from 'vue'
const state = { }

const getters = {
  // state is module's local state
}

const mutations = {
  // state is assigned a value
}

const actions = {
  getAvailable: function () {
    return new Promise ((resolve, reject) => {
      service.getAvailable().then (res => { 
        resolve (res) 
      }).catch (err => { 
        reject (err) 
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
